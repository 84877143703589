'use strict';

class Request {
  constructor() {
    // this.rootuUrl = 'http://localhost:3000/api/';
    this.rootuUrl = 'https://mibandapi.identitaetsstiftung.dev/';
    this.token = false;
  }

  _response(request) {
    let response = '';
    try {
      response = JSON.parse(request.responseText);
    } catch(e) {
      console.error(e, request.responseText);
      response = request.responseText;
    }
    return response;
  }

  _call(subUrl, methode = 'POST', data = false) {
    const request = new XMLHttpRequest();
    const promise = (resolve, reject) => {
      request.open(methode, this.rootuUrl + subUrl);


      request.addEventListener('load', (evt) => {
        resolve(this._response(request));
      });

      request.addEventListener('error', (err) => {
        reject(err);
      });

      request.setRequestHeader(
        'Content-Type', 'application/json'
      );
      
      if(this.token) {
        request.setRequestHeader(
          'x-lauftracker-authtoken', this.token
        );
      }

      if(data) {
        request.send(JSON.stringify(data));
      } else {
        request.send();
      }
    }

    return new Promise(promise);
  }

  setToken(token) {
    this.token = token;
  }
}
module.exports = Request;
