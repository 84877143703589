'use strict';

import Request from './request';

class User extends Request {
  constructor() {
    super();
  }

  get() {
    return this._call(`user/`, 'GET');
  }
}

export default User;
