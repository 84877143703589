'use strict';

import Auth from './api/auth';
import Group from './api/group';
import User from './api/user';

const debugToken = false;
const infoDisplay = document.getElementById('infoDisplay');

function getToken() {
  if(debugToken) {
    return debugToken;
  } else {
    return sessionStorage.getItem('token');
  }
}

function setUserData() {
  function removeFromGroup(btn) {
    const groupId = btn.dataset.id;
    const group = new Group();
    group.setToken(getToken());
    group.removeFrom(groupId)
      .then(data => {
        infoDisplay.innerHTML = 'erfolgreich ausgetreten'
        setUserData();
      })
      .catch(() => infoDisplay.innerHTML = 'fehler beim austreten');
  }
  const userSteps = document.getElementById('userSteps');
  const userGroup = document.getElementById('userGroup');
  const user = new User();
  user.setToken(getToken());
  user.get()
    .then(data => {
      let allSteps = 0
      data.data.steps.forEach(element => allSteps += element.steps);
      userSteps.innerHTML = allSteps;

      userGroup.innerHTML = '';
      if(!data.data.group.name) {
        return;
      }
      let label = document.createElement('label');
      label.innerHTML = data.data.group.name;
      let btn = document.createElement('button');
      btn.setAttribute('data-id', data.data.group.id);
      btn.addEventListener('click', () => removeFromGroup(btn));
      btn.innerHTML = 'austreten';
      userGroup.appendChild(label);
      userGroup.appendChild(btn);
    })
    .catch(err => console.error(err));
}

/**
 * handle the create group "form"
 */
function createGroup() {
  const createGroupName = document.getElementById('createGroupName');
  const createGroupBtn  = document.getElementById('createGroupBtn');
  const group = new Group();

  createGroupBtn.addEventListener('click', function() {
    if(createGroupName.value.length < 4) {
      infoDisplay.innerHTML = 'Gruppen Name muss mehr als 4 Zeichen enthalten';
      return;
    }
    group.setToken(getToken());
    group.newGroup(createGroupName.value)
      .then(data => {
        infoDisplay.innerHTML = 'Gruppe hinzugefügt.';
        allGroup();
      })
      .catch(err => {
        console.error(err);
      })
  });
  
}

function setMemberEvent() {
  function getMemberFromGroup(button) {
    const groupMemebers = document.getElementById('groupMemebers');
    const groupId = button.dataset.id;
    const group = new Group();
    group.setToken(getToken());
    group.getInfos(groupId)
      .then(data => {
        groupMemebers.innerHTML = '';                                                                                                                                                                                                                                                   
        if(data.data.member.length == 0) {
          infoDisplay.innerHTML = 'Gruppe ist leer';        
          return;
        }
        for(let member of data.data.member) {
          let li = document.createElement('li');
          li.innerHTML = `${member.firstname} ${member.lastname}`;
          groupMemebers.appendChild(li);
        }
        console.log(data);
      })
      .catch(err => {
        console.log(err);
      })
  }
  const allGroups = document.getElementById('allGroups');
  const dataMemeberBtns = allGroups.querySelectorAll('[data-members]');
  for(let btn of dataMemeberBtns) {
    btn.addEventListener('click', function(){getMemberFromGroup(btn)});
  }


}

function setAddGroupEvent() {
  function addToGroup(button) {
    const groupId = button.dataset.id;
    const group = new Group();
    group.setToken(getToken());
    group.addTo(groupId)
      .then(data => {
        if(data.success) {
          infoDisplay.innerHTML = 'Erfolgreich zur Gruppe hinzugefügt';
          setUserData();
        } else {
          infoDisplay.innerHTML = data.error.type;
        }
      })
      .catch(err => {
        console.log(err);
      })
  }
  const allGroups = document.getElementById('allGroups');
  const dataMemeberBtns = allGroups.querySelectorAll('[data-add]');
  for(let btn of dataMemeberBtns) {
    btn.addEventListener('click', function(){addToGroup(btn)});
  }


}

/**
 * fill the group table 
 */
function allGroup() {
  const allGroups  = document.getElementById('allGroups');
  const trTemplate = document.getElementById('trTemplate');
  const allGroupsTrList = allGroups.querySelectorAll('tr');
  for(let tr of allGroupsTrList) {
    if(!tr.classList.contains('no-delete')) {
      tr.parentNode.removeChild(tr);
    }
  }
   
  const group = new Group();
  group.setToken(getToken());
  group.getAll()
    .then(data => {
      
      for(let group of data.data) {
        let trTemplateString = trTemplate.innerHTML;
        trTemplateString = trTemplateString.replace('{{name}}', group.name);
        trTemplateString = trTemplateString.replace('{{groupid}}', group.id);
        trTemplateString = trTemplateString.replace('{{groupid}}', group.id);
        let tr = document.createElement('tr');
        tr.innerHTML = trTemplateString;
        allGroups.appendChild(tr);
      }
      setMemberEvent();
      setAddGroupEvent();
    })
    .catch(err => {
      console.error(err);
    })
}

/**
 * handle the auth checkbox and the input fields
 */
function auth() {
  const login    = document.getElementById('checkLogin');
  const register = document.getElementById('checkRegister');
  const authBtn  = document.getElementById('authBtn');

  const pFirstname = document.getElementById('pFirstname');
  const pLastname  = document.getElementById('pLastname');

  login.addEventListener('change', function() {
    if(!login.checked) {
      return;
    }
    pFirstname.style.display = 'none';
    pLastname.style.display = 'none';
    authBtn.innerHTML = 'Login';
  });

  register.addEventListener('change', function() {
    if(!register.checked) {
      return;
    }
    pFirstname.style.display = 'block';
    pLastname.style.display = 'block';
    authBtn.innerHTML = 'Register';
  });

  return [login, register, authBtn];
}

/**
 * handle the register and login form and display the logged area
 */
function authForm(loginCheck, registerCheck, authBtn) {
  const email     = document.getElementById('email');
  const password  = document.getElementById('password');
  const firstname = document.getElementById('firstname');
  const lastname  = document.getElementById('lastname');
  const authArea    = document.getElementById('authArea');
  const loggedAuth  = document.querySelectorAll('.loggedAuth');
  const auth = new Auth();

  function setAuthData(data) {
    if(!data.success) {
      infoDisplay.innerHTML = data.error.type;
    } else {
      infoDisplay.innerHTML = 'Erfolgreich angemeldet!';
      const token = data.data.token;
      const user = data.data.user;
      sessionStorage.setItem('token', token);
      sessionStorage.setItem('user', JSON.stringify(user));
      authArea.style.display = 'none';
      loggedAuth.forEach(element => element.style.display = 'block')
      // loggedAuth.style.display = 'block';
      allGroup();
      setUserData();
    }
  }

  authBtn.addEventListener('click', function() {
    infoDisplay.innerHTML = 'Sendet daten...';
    if(loginCheck.checked) {
      auth.login(email.value, password.value)
        .then(data => {
          setAuthData(data);
        })
        .catch(err => {
          console.error(err);
        })
    } else if(registerCheck.checked) {
      auth.register(email.value, password.value, firstname.value, lastname.value)
        .then(data => {
          setAuthData(data);
        })
        .catch(err => {
          console.error(err);
        })
    }
  });
}



function formsHandle() {
  let [loginCheck, registerCheck, authBtn] = auth();
  authForm(loginCheck, registerCheck, authBtn);
  createGroup();

  if(debugToken) {
    allGroup();
    setUserData();
  }
}

export default formsHandle;
