'use strict';

import Request from './request';

class Group extends Request {
  constructor() {
    super();
  }


  getAll() {
    return this._call('groups/', 'GET');
  }

  getInfos(groupId) {
    return this._call(`groups/infos/${groupId}`, 'GET');
  }

  newGroup(name) {
    let data = {
      name: name
    };
    return this._call('groups/new', 'POST', data);
  }

  addTo(groupId) {
    return this._call(`groups/add-to/${groupId}`, 'POST');
  }

  removeFrom(groupId) {
    return this._call(`groups/remove-from/${groupId}`, 'POST');
  }

  remove(groupId) {
    return this._call(`groups/remove/${groupId}`, 'POST');
  }
}

export default Group;
