'use strict';

import Request from './request';

class Auth extends Request {
  constructor() {
    super();
  }

  login(email, password) {
    let data = {
      email: email,
      password: password
    };
    return this._call('auth/login', 'POST', data);
  }

  register(email, password, firstname, lastname) {
    let data = {
      email: email,
      password: password,
      firstname: firstname,
      lastname: lastname
    };
    return this._call('auth/register', 'POST', data);
  }
}

export default Auth;
